export const PENGUMUMAN_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Tanggal',
    'Judul',
    'Tautan (URL)',
    'File',
    // 'Unit',
    // 'Nama Folder',
    '',
  ],
  value: [
    {},
    { type: 'string', attribute: 'publish_date' },
    { type: 'string', attribute: 'name' },
    // { type: 'string', attribute: 'levels' },
    // { type: 'string', attribute: 'folder_name' },
    { type: 'link', attribute: 'hyperlink'},
  ],
};
 export const JADWAL_KELAS_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Judul',
    'Unit',
    'Tanggal Mulai',
    'Tanggal Selesai',
    '',
  ],
  value: [
    {},
    { type: 'string', attribute: 'name' },
    { type: 'string', attribute: 'levels' },
    { type: 'string', attribute: 'publish_date' },
    { type: 'string', attribute: 'tgl_selesai' },
  ],
};

  export const JADWAL_KARYAWAN_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Judul',
      'Unit',
      'Golongan',
      'Jabatan',
      '',
    ],
    value: [
      {},
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'units' },
      { type: 'string', attribute: 'golongan' },
      { type: 'string', attribute: 'jabatan' },
    ],
  };
  export const FOLDER_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Code',
      'Name',
      'Deskripsi',
      'Aktif',
      '',
    ],
    value: [
      {},
      { type: 'string', attribute: 'folder_code' },
      { type: 'string', attribute: 'folder_name' },
      { type: 'string', attribute: 'folder_description' },
      { type: 'string', attribute: 'folder_active'},
    ],
  };

  export const LIST_SOAL_FROM_TABEL = {
    label: [
      'No',
      'Tanggal',
      'Judul',
      'Mata Pelajaran',
      'Unit',
      'Status',
      'Opsi',
    ],
    value: [
      {},
      { type: 'string', attribute: 'kode_jenis' },
      { type: 'string', attribute: 'nama_jenis' },
    ],
  };


  export const ABSEN_REKAP_LIST_TABLE_TOTAL_FIELDS = {
    label: [
      'No',
      'No VA',
      'Nama Siswa',
      'Hadir',
      'Sakit',
      'Izin',
      'Alpa',
      // 'Persen',
      // '',
    ],
    
    value: [
      {},
      { type: 'string', attribute: 'no_va' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'status' },
      { type: 'string', attribute: 'total'},
      // { type: 'string', attribute: 'percent'},
    ],
  };
  export const ABSEN_REKAP_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'No VA',
      'Nama Siswa',
      'Status',
      // 'Persen',
      // '',
    ],
    
    value: [
      {},
      { type: 'string', attribute: 'no_va' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'status' },
      // { type: 'string', attribute: 'percent'},
    ],
  };
  export const CETAK_ABSEN_REKAP_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'NISN',
      'No VA',
      'Nama Siswa',
      'Status',
      'Keterangan',
      'Jumlah Hadir',
      '%',
      '',
    ],
    
    value: [
      {},
      { type: 'string', attribute: 'nisn' },
      { type: 'string', attribute: 'no_va' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'stataus' },
      { type: 'link', attribute: 'keterangan'},
      { type: 'string', attribute: 'jml_hadir' },
      { type: 'link', attribute: 'percent'},
    ],
  };




  export const ATTENDANCE_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'No VA',
      'Nama',
      'Hadir',
      'Izin',
      'Sakit',
      'Alpa',
      'Terlambat',
      // 'Keterangan',
    ],
    value: [
      {},
      { type: 'string', attribute: 'no_va' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'status', value: 'HADIR' },
      { type: 'string', attribute: 'status', value: 'IZIN' },
      { type: 'string', attribute: 'status', value: 'SAKIT' },
      { type: 'string', attribute: 'status', value: 'ALPA' },
      { type: 'string', attribute: 'status', value: 'TERLAMBAT' },
      // { type: 'string', attribute: 'description'},
    ],
  };
  
  export const TEACHER_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'NIP',
      'NUPTK',
      'Nama Guru',
      'P/L',
      'Tempat Tanggal Lahir',
    ],
    value: [
      {},
      { type: 'string', attribute: 'nip' },
      { type: 'string', attribute: 'nuptk' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'genders' },
      { type: 'string', attribute: 'pob_dob' },

    ],
  };

  export const PEMBAGIAN_KELAS_TABEL_FIELDS = {
    label: [
      'No',
      'Pilihan',
      'NISN',
      'No VA',
      'Nama Siswa',
      // 'L/P',
    ],
    value: [
      {},
      { type: 'string', attribute: 'checkbox', value: 'true'},
      { type: 'string', attribute: 'nisn' },
      { type: 'string', attribute: 'no_va' },
      { type: 'string', attribute: 'name' },
      // { type: 'string', attribute: 'classrooms_id'},

    ],
  };

  export const PEMBAGIAN_KELAS_PPDB_TABEL_FIELDS = {
    label: [
      'No',
      'Pilihan',
      'NISN',
      'No Pendaftaran',
      'Nama Siswa',
      // 'L/P',
    ],
    value: [
      {},
      { type: 'string', attribute: 'checkbox', value: 'true'},
      { type: 'string', attribute: 'nisn' },
      { type: 'string', attribute: 'no_va' },
      { type: 'string', attribute: 'fullname' },
      // { type: 'string', attribute: 'classrooms_id'},

    ],
  };

  export const KENAIKAN_KELAS = {
    label: [
      'No',
      'Pilihan',
      'NISN',
      'No VA',
      'Nama Siswa',
      // 'L/P',
    ],
    value: [
      {},
      { type: 'string', attribute: 'checkbox', value: 'true'},
      { type: 'string', attribute: 'nisn' },
      { type: 'string', attribute: 'no_va' },
      { type: 'string', attribute: 'fullname' },
      // { type: 'string', attribute: 'classrooms_id'},

    ],
  };

  export const PEMBATALAN_SISWA_TABEL_FIELD = {
    label: [
      'No',
      'Pilihan',
      'NISN',
      'No VA',
      'Nama Siswa',
      // 'L/P',
    ],
    value: [
      {},
      { type: 'string', attribute: 'checkbox', value: 'true'},
      { type: 'string', attribute: 'nisn' },
      { type: 'string', attribute: 'no_va' },
      { type: 'string', attribute: 'students_name' },
      // { type: 'string', attribute: 'classrooms_id'},

    ],
  };
  

  export const PENGATURAN_KELAS_PPDB_TABEL_FIELDS = {
    label: [
      'No',
      'NISN',
      'No VA',
      'Nama Siswa',
      'Nama Kelas',
      'Opsi',
    ],
    value: [
      {}, 
      { type: 'string', attribute: 'nisn' }, 
      { type: 'string', attribute: 'no_va' }, 
      { type: 'string', attribute: 'fullname' }, 
      { type: 'string', attribute: 'classrooms_name'},
    ],
  };

  export const PENGATURAN_KENAIKAN_KELAS_TABEL_FIELDS = {
    label: [
      'No',
      'NISN',
      'No VA',
      'Nama Siswa',
      'Nama Kelas',
      'Opsi',
    ],
    value: [
      {}, 
      { type: 'string', attribute: 'nisn' }, 
      { type: 'string', attribute: 'no_va' }, 
      { type: 'string', attribute: 'fullname' }, 
      { type: 'string', attribute: 'classrooms_name'},
    ],
  };

  export const PENGATURAN_SISWA_LULUS_TABEL_FIELDS = {
    label: [
      'No',
      'NISN',
      'No VA',
      'Nama Siswa',
      'Opsi',
    ],
    value: [
      {}, 
      { type: 'string', attribute: 'nisn' }, 
      { type: 'string', attribute: 'no_va' }, 
      { type: 'string', attribute: 'fullname' }, 
    ],
  };

  export const UPLOAD_ABSENSI_HARIAN_FORM_FIELDS = [{
    type: 'file',
    name: 'file',
    label: 'File Name',
    placeholder: 'Pilih file',
    inputType: 'file',
    full: true,
  }];
  export const KODE_MATPEL_TABLE_FIELDS = {
    label: [
      'No',
      'Kode',
      'Mata Pelajaran',
      'Tingkat Kelas',
      'Nilai KKM',
      'Opsi',
    ],
    value: [
      {},
      { type: 'string', attribute: 'subject_code' },
      { type: 'string', attribute: 'subject_name' },
      { type: 'string', attribute: 'classes_name' },
      { type: 'string', attribute: 'kkm'},
    ],
  };
  export const PINDAH_KELAS_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Pilihan',
      'NISN',
      'No VA',
      'Nama Siswa',
      'L/P',
    ],
    value: [
      {},
      { type: 'string', attribute: 'checkbox', value: 'true'},
      { type: 'string', attribute: 'nisn' },
      { type: 'string', attribute: 'no_va' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'genders'},

    ],
  };

  export const JENIS_SPP_FORM_TABLE = {
    label: [
      'No',
      'Kode',
      'Nama Pembayaran',
      'Diskon',
      // 'Rutin',
      'Opsi',
    ],
    value: [
      {},
      { type: 'string', attribute: 'coa' },
      { type: 'string', attribute: 'name' },
      { type: 'string', attribute: 'is_discount' },
      // { type: 'string', attribute: 'is_routine' },
    ],
  };

  export const KONSELING_SISWA_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Tgl',
      'NISN',
      'No VA',
      'Nama',
      'Kelas',
      'Materi Konseling',
      'Tindakan/Sanksi',
      'Opsi',
    ],
    value: [
      {},
      { type: 'string', attribute: 'date' },
      { type: 'string', attribute: 'nisn' },
      { type: 'string', attribute: 'no_va' },
      { type: 'string', attribute: 'students_name' },
      { type: 'string', attribute: 'classrooms_name' },
      { type: 'string', attribute: 'materials' },
      { type: 'string', attribute: 'descriptions' },
    ],
  };

  export const TAMBAH_TEMPAT_KONSELING_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Code',
      'Name',
      'Deskripsi',
      'Aktif',
      'Opsi',
    ],
    value: [
      {},
      { type: 'string', attribute: 'code_place' },
      { type: 'string', attribute: 'place_name' },
      { type: 'string', attribute: 'description' },
      { type: 'string', attribute: 'folder_active' },
    ],
  };

  export const BUAT_CATATAN_KONSELING_LIST_TABLE_FIELDS = {
    label: [
      'No',
      'Tgl Konseling',
      'Catatan Khusus',
      'Inti Masalah',
      'Penyelesaian',
      'Keterangan',
      'Kesimpulan',

    ],
    value: [
      {},
      { type: 'string', attribute: 'date' },
      { type: 'string', attribute: 'specific_record' },
      { type: 'string', attribute: 'problems' },
      { type: 'string', attribute: 'problems_solving' },
      { type: 'string', attribute: 'descriptions' },
      { type: 'string', attribute: 'conclusion' },

    ],
  };

  export const TABLE_LIST_GROUP_MATPEL_TABLE_FIELDS={
    label: [
        'No',
        'Nama Grup Pelajaran',
        'Opsi',
 
    ],
    value: [
        {},
        {type: 'string', attribute:'name'},
    ]
 
 
 };

 export const PINDAH_UNITS_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Pilihan',
    'NISN',
    'No VA',
    'Nama Siswa',
    'L/P',
  ],
  value: [
    {},
    { type: 'string', attribute: 'checkbox', value: 'true'},
    { type: 'string', attribute: 'nisn' },
    { type: 'string', attribute: 'no_va' },
    { type: 'string', attribute: 'name' },
    { type: 'string', attribute: 'genders'},

  ],
};

export const DAFTAR_PINDAH_UNITS_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Unit Tujuan',
    'NISN',
    'No VA',
    'Nama Siswa',
    'L/P',
    'Status',
    'Opsi',
  ],
  value: [
    {},
    { type: 'string', attribute: 'unit_to'},
    { type: 'string', attribute: 'nisn' },
    { type: 'string', attribute: 'no_va' },
    { type: 'string', attribute: 'name' },
    { type: 'string', attribute: 'genders'},
    { type: 'string', attribute: 'status'},

  ],
};

export const PERMOHONAN_PINDAH_UNITS_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Unit Asal',
    'NISN',
    'No VA',
    'Nama Siswa',
    'L/P',
    'Status',
    'Opsi',
  ],
  value: [
    {},
    { type: 'string', attribute: 'unit_from'},
    { type: 'string', attribute: 'nisn' },
    { type: 'string', attribute: 'no_va' },
    { type: 'string', attribute: 'name' },
    { type: 'string', attribute: 'genders'},
    { type: 'string', attribute: 'status'},
  ],
};

export const TABLE_LIST_TEMA_TABLE_FIELDS={
    label: [
        'No',
        'Nama Tema',
        'Semester',
        'Opsi',
 
    ],
    value: [
        {},
        {type: 'string', attribute:'name'},
        {type: 'string', attribute:'semester'},
    ]
 
 
};

export const TABLE_LIST_SUB_TEMA_TABLE_FIELDS={
    label: [
        'No',
        'Nama Sub Tema',
        'Opsi',
 
    ],
    value: [
        {},
        {type: 'string', attribute:'name'},
    ]
 
 
};

export const MATA_PELAJARAN_LIST_TABLE_FIELDS = {
  label: [
    'No',
    'Mata Pelajaran',
    'KKM',
    'Nilai',
    'Deskripsi',
  ],
  
  value: [
    {},
    { type: 'string', attribute: 'name' },
    { type: 'string', attribute: 'kkm' },
    { type: 'string', attribute: 'score' },
    { type: 'string', attribute: 'descriptions' },

  ],
};

export const NILAI_RAPORT_LIST_TABLE_FIELDS = {
  label: [
    'No',
    // 'NISN',
    'No VA',
    'Nama Siswa',
    // 'Keterangan',
    'Opsi',
  ],
  
  value: [
    {},
    // { type: 'string', attribute: 'nisn' },
    { type: 'string', attribute: 'no_va' },
    { type: 'string', attribute: 'name' },
    // { type: 'string', attribute: 'keterangan' },
  ],
};
