import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, noop, isEqual, map, capitalize } from "lodash";
import StudentReportFilter from "../../../components/Filter/StudentReport.filter.component";
import ListForm from "../../../components/ListForm/ListForm.component";
import ContentTable from "../../../components/ContentTable/ContentTable.component";
import { ABSENSI_REKAP_PAGE2 } from "../../../constants/student.constant";
import { ABSENSI_REKAP_TABLE } from "../../../constants/student.constant";
import { validateRequiredFields } from "../../../utils/validation.util";
import {
  commaFormatted,
  normalizeAmount,
  formatData,
} from "../../../utils/transformer.util";
import {
  Button,
  Select,
  Pagination,
  SearchSelect,
  Input,
  InputDate,
} from "../../../components/base/index";
import language from "../../../languages";
import AbsensiRekapForm from "../components/AbsensiRekapForm.component";
import AbsensiRekapTable from "../components/AbsensiRekapTable.component";
import AbsensiRekapPage2 from "../components/AbsensiRekapPage2.component";
import { ABSEN_REKAP_LIST_TABLE_TOTAL_FIELDS } from "../../../constants/kurikulum/kurikulum.constant";
import { ABSEN_REKAP_LIST_TABLE_FIELDS } from "../../../constants/kurikulum/kurikulum.constant";
import ReactToPrint from "react-to-print";

const initialContent = {
  loading: true,
  list: [],
  currentPage: 1,
  total: 1,
  totalPage: 1,
  error: false,
};

export default class AbsensiRekap extends Component {
  constructor(props) {
    super(props);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onClickNext = this._onClickNext.bind(this);
    this.onClickAddGrafik = this.onClickAddGrafik.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    // this._getStudentPeriodOptions = this._getStudentPeriodOptions.bind(this);
    this._getStudentPeriodOptions = this._getStudentPeriodOptions.bind(this);
    this._onChangeFilter = this._onChangeFilter.bind(this);
    this._onChangePage = this._onChangePage.bind(this);
    this._onChangeListAmount = this._onChangeListAmount.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this._getParamOptionsAcademics = this._getParamOptionsAcademics.bind(this);
    //this.deletePlafon = this.deletePlafon.bind(this);
    this._getPeriodsOptions = this._getPeriodsOptions.bind(this);
    this._studentCount = this._studentCount.bind(this);
    this._onClickSet = this._onClickSet.bind(this);

    this.state = {
      page: 1,
      selected: {},
      content: initialContent,
      unchecked: {},
      filters: {},
      isTable: true,
      list: [],
      form: {
        value: {
          absensiharian: [],
          classrooms: [],
          levels: [],
          classrooms_id: "",
        },
        error: {
          absensiharian: "",
          classrooms: "",
          levels: "",
        },
      },
      param: {},
      filtered: false,
      total_siswa: "",
    };
    this._handleFormChange = this._handleFormChange.bind(this);
    this._onSearch = this._onSearch.bind(this);
  }

  componentDidMount() {
    const {
      handleGetCodeOfAccount,
      location,
      handleGetclassrooms,
      handleGetLevels,
      user,
    } = this.props;
    const { school_unit, organizations_id } = user;
    const paramTypes = ["classrooms", "classrooms"];
    const { content } = this.state;
    paramTypes.forEach((type) => {
      this._getParamOptionsAcademics(type);
    });
    if (isEmpty(content.list)) {
      this._onSearchContent({ page: 1 });
    }
    this._getStudentPeriodOptions({ units_id: [school_unit.id] });
    handleGetclassrooms();
    handleGetLevels();
    this._studentCount();
    this._getPeriodsOptions({ units_id: school_unit.id });
    this._getPrmLevels({
      filters: {
        units_id: [school_unit.id],
        organizations_id: organizations_id,
      },
    });

    // const { data = {} } = location.state;

    // this._searchCoa();

    // if (!isEmpty(data)) {
    //   this._setForm(data);
    // }
  }

  // _searchCoa(keyword = null) {
  //   const { handleGetCodeOfAccount } = this.props;

  //   handleGetCodeOfAccount({
  //     keyword,
  //     classrooms: [50000],
  //   });
  // }

  // on formchange untuk form
  componentDidUpdate(prevProps) {
    // const { user } = this.props;
    // const { workingUnit } = user;
    // const { user: prevUser } = prevProps;
    // const { workingUnit: prevWorkingUnit } = prevUser;

    // if (!isEqual(workingUnit, prevWorkingUnit)) {
    //   this.handleGetData();
    // }
    if (!isEqual(prevProps, this.props)) {
      this._onSearchContent({ page: 1 });
    }
  }

  async _studentCount(payload = {}) {
    const { filters, form } = this.state;
    const { value } = form;
    const { handleStudentCount, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { school_unit_id, levels_id } = school_unit;
    const res = await handleStudentCount({
      ...payload,
      organizations_id: organizations_id,
      units_id: school_unit.id,
      filters,
    });
    // filters.organizations_id = organizations_id;
    // filters.classrooms_id = school_unit_id;

    this.setState((prevState) => ({
      ...prevState,
      total_siswa: res,
    }));
  }

  async _getPrmLevels(filters = {}) {
    const { getPrmLevels, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPrmLevels(filters);
    filters.organizations_id = organizations_id;

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        levels: res,
      },
    }));
  }

  _handleFormChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          [name]: value,
        },
        error: {
          ...prevState.form.error,
          [name]: "",
        },
      },
    }));
  }
  async _getStudentPeriodOptions(filters = {}) {
    const { getStudentPeriodOptions, user } = this.props;
    const { school_unit } = user;

    const res = await getStudentPeriodOptions(filters);
    filters.units_id = school_unit.id;
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        student: res,
      },
    }));
  }

  async _getPeriodsOptions(filters = {}) {
    const { getPeriodsOptions, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { units_id, levels_id } = school_unit;
    const res = await getPeriodsOptions(filters);
    map(res, (data, idx) => {
      var date = new Date();
      var start_date = new Date(data.attributes.start_date);
      if (date > start_date) {
        // tahun ajaran sekarang
        this.setState((prevState) => ({
          ...prevState,
          filters: {
            ...prevState.filters,
            period: data.value,
          },
        }));
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        periods: res,
      },
    }));
  }

  _onSearch() {
    const { form } = this.state;
    const error = validateRequiredFields(form.value, ["year"]);
    this.setState((prevState) => ({
      form: {
        value: prevState.form.value,
        error,
      },
    }));
    if (isEmpty(error)) {
      this.setState({ filtered: true });
    }
  }

  async _onSearchContent(params = {}) {
    const { filters } = this.state;
    // console.log(filters.students_id.value);
    if (filters.students_id && filters.students_id.value == "") {
      delete filters.students_id;
    }
    this.setState(
      {
        content: initialContent,
      },
      async () => {
        try {
          const { handleListStudentRekapAttendance, user } = this.props;
          const { school_unit, workingUnit, id, user_group, organizations_id } =
            user;

          let result = {};

          result = await handleListStudentRekapAttendance({
            ...params,
            organizations_id: organizations_id,
            units_id: school_unit.id,
            filters,
          });

          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                absensiharian: result,
              },
            },
          }));
          // }
        } catch (err) {
          // if (this.isMount) {
          this.setState((prevState) => ({
            ...prevState,
            form: {
              ...prevState.form,
              value: {
                ...prevState.form.value,
                absensiharian: [],
              },
            },
          }));
        }
        // }
      }
    );
  }

  _onChangeListAmount(event) {
    const { keywords } = this.state;
    this.setState({
      listAmount: Number(event.target.value),
    });
    this._onSearchContent({ limit: event.target.value, keywords, page: 1 });
  }

  _onChangeFilter(e) {
    const { target } = e;
    const { value, name } = target;
    const { user } = this.props;
    const { organizations_id } = user;
    const { filters } = this.state;
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      () => {
        // this._onSearchContent();
        if (name === "classrooms_id") {
          this._studentCount({ filters: { classrooms_id: [value] } });
          this._getStudentPeriodOptions({
            filters: {
              classrooms_id: [value],
              organizations_id: organizations_id,
              period: filters.period,
            },
          });
        }
      }
    );
  }

  _onChangePage(page) {
    const { listAmount, keywords } = this.state;
    const offset = listAmount * (page - 1);
    // this.handleGetData({
    this._onSearchContent({
      limit: listAmount,
      keywords,
      offset,
      page,
    });
  }

  // on formchange untuk tabel
  _onFormChange(event) {
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
    } = dataset;
    const { user } = this.props;
    const { organizations_id } = user;
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        if (inputType === "number") {
          formattedValue = normalizeAmount(value);
        }
        if (inputArray) {
          if (type === "checkbox") {
            formattedValue = checked ? value : "";
          }
          newList = prevState.form.value[fieldName];
          newListError = prevState.form.error[fieldName] || [];
          newList[arrayPosition][name] = formattedValue;
          if (name === "code_of_account") {
            newList[arrayPosition].isCredit = value.type;
          }
          if (!isEmpty(newListError[arrayPosition])) {
            newListError[arrayPosition][name] = "";
          }
        }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {
        if (name === "classrooms_id") {
          this._getParamOptions("classrooms", {
            filters: { levels_id: value, organizations_id: organizations_id },
          });
          this._getStudentPeriodOptions({
            filters: {
              classrooms_id: [value],
              organizations_id: organizations_id,
            },
          });
        }
      }
    );
  }

  onSubmit(e) {
    e.preventDefault();
    const { history } = this.props;
    history.push(
      "/dashboard/kesiswaan/absensi-siswa/absensi-rekap/cetak-absensi-rekap"
    );
  }

  onClickAddGrafik() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/absensi-siswa/absensi-rekap/grafik", {
      isEdit: true,
    });
  }

  _onClickNext() {
    const { history } = this.props;
    history.push("/dashboard/kesiswaan/laporan/cetak-laporan-8355");
  }

  onClickEdit(val) {
    const { history } = this.props;
    console.log(val);
    history.push("/dashboard/kesiswaan/absensi-siswa/tambah-absensi-harian", {
      date: val.date,
      classrooms_id: val.classrooms_id,
      period: val.period,
      isEdit: true,
    });
  }

  _renderButtons(data) {
    const { user } = this.props;
    const { user_group } = user;
    const { permissions = [] } = user_group;

    return (
      <td>
        <div className="table__actions">
          <Button onClick={() => this.onClickEdit(data)} title="Edit" />

          {/* </div> */}
          {/* } */}
        </div>
      </td>
    );
  }

  async _getParamOptionsAcademics(type, filters = {}) {
    const { getParamOptionsAcademics, user } = this.props;
    const { organizations_id, school_unit } = user;
    const { levels_id } = school_unit;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
      filters.units_id = school_unit.id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptionsAcademics(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  async _getParamOptions(type, filters = {}) {
    const { getParamOptions, user } = this.props;
    const { organizations_id } = user;
    if (type === "classrooms" || type === "classrooms") {
      filters.organizations_id = organizations_id;
    } else {
      delete filters.organizations_id;
    }
    const res = await getParamOptions(filters, type);
    this.setState((prevState) => ({
      ...prevState,
      param: {
        ...prevState.param,
        [type]: res,
      },
    }));
  }

  _onClickSet() {
    this.setState(
      (prevState) => ({
        ...prevState,
        isTable: false,
        filters: {
          ...prevState.filters,
        },
      }),
      () => {
        this._onSearchContent();
      }
    );
  }

  renderHadir(absensiharian) {
    // const { absensiharian = {}} = this.state;
    const { attendance } = absensiharian;
    const row = [];

    const type = (attendance ? attendance.find((i) => i.status == "HADIR") : "")
      ? attendance.find((i) => i.status == "HADIR")
      : null;

    console.log(type);
    if (type !== null) {
      row.push([<td>{type.total}</td>]);
    } else {
      row.push([<td></td>]);
    }
    return row;
  }

  renderSakit(absensiharian) {
    // const { absensiharian = {}} = this.state;
    const { attendance } = absensiharian;
    const row = [];

    const type = (attendance ? attendance.find((i) => i.status == "SAKIT") : "")
      ? attendance.find((i) => i.status == "SAKIT")
      : null;

    console.log(type);
    if (type !== null) {
      row.push([<td>{type.total}</td>]);
    } else {
      row.push([<td></td>]);
    }
    return row;
  }

  renderIzin(absensiharian) {
    // const { absensiharian = {}} = this.state;
    const { attendance } = absensiharian;
    const row = [];

    const type = (attendance ? attendance.find((i) => i.status == "IZIN") : "")
      ? attendance.find((i) => i.status == "IZIN")
      : null;

    console.log(type);
    if (type !== null) {
      row.push([<td>{type.total}</td>]);
    } else {
      row.push([<td></td>]);
    }
    return row;
  }

  renderAlpa(absensiharian) {
    // const { absensiharian = {}} = this.state;
    const { attendance } = absensiharian;
    const row = [];

    const type = (attendance ? attendance.find((i) => i.status == "ALPA") : "")
      ? attendance.find((i) => i.status == "ALPA")
      : null;

    console.log(type);
    if (type !== null) {
      row.push([<td>{type.total}</td>]);
    } else {
      row.push([<td></td>]);
    }
    return row;
  }

  render() {
    const {
      param,
      form,
      page,
      list = {},
      selected,
      checkedAll,
      content,
      filters,
      listAmount,
      total_siswa,
      isTable,
    } = this.state;
    const { absensiharian } = form.value;
    const {
      classrooms_id = "",
      students_id = "",
      period = "",
      date = "",
      date_to = "",
      status_id = "",
      menu_id = "",
    } = filters;
    const { classrooms = {}, levels = {} } = this.props;
    const academic_year = [
      { label: "2019", value: "2019" },
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
    ];
    const status = [
      { label: "Hadir", value: "HADIR" },
      { label: "Sakit", value: "SAKIT" },
      { label: "Izin", value: "IZIN" },
      { label: "Alpa", value: "ALPA" },
      { label: "Terlambat", value: "TERLAMBAT" },
    ];

    const menu = [
      { label: "Tampilkan Total", value: "total" },
      { label: "Tampilkan Keterangan", value: "desc" },
    ];
    const printTest = `
    @media print {
      @page {
        page-break-inside: avoid;
      }
      * {
        -webkit-print-color-adjust: exact;
      }
    }`;
        const componentRef = React.createRef();
    
    return (
      <div className="manage-registration">
        <form onSubmit={this.onSubmit}>
          <div className="budget__title">
            <h1>Presensi Rekap</h1>
          </div>

          <div className="kode-matpel__custom-form">
            <div className="kode-matpel__custom-form-row"></div>
            <div className="kode-matpel__custom-form-row">
              <div className="kode-matpel__custom-form-row__field">
                <Select
                  name="period"
                  label="Tahun Ajaran"
                  onChange={this._onChangeFilter}
                  // data={ levels.list}
                  value={period}
                  placeholder={"Pilihan"}
                  data={param.periods}
                  // error={form.error.namaSaudara1 || ''}
                />
              </div>

              <div className="kode-matpel__custom-form-column__field">
                <Select
                  placeholder="Pilih Kelas"
                  label="Kelas"
                  name="classrooms_id"
                  value={classrooms_id}
                  data={param.classrooms}
                  onChange={this._onChangeFilter}
                />
              </div>
            </div>

            <div className="kode-matpel__custom-form-row">
              <div className="kode-matpel__custom-form-row__field">
                <div className="data-pelanggaran__custom-form-row__field">
                  <InputDate
                    type="date"
                    name="date"
                    label="Tanggal"
                    onChange={this._onChangeFilter}
                    value={date}
                  />

                  <div className="data-pelanggaran__custom-form-row__field"></div>
                  {/* {filters.menu_id == "total" ? ( */}
                  <InputDate
                    type="date"
                    name="date_to"
                    label="Sampai Dengan"
                    onChange={this._onChangeFilter}
                    value={date_to}
                  />
                  {/* ) : (
                    ""
                  )} */}
                </div>
              </div>

              <div className="kode-matpel__custom-form-column__field"></div>
            </div>
            <div>
              <Button title="Proses" onClick={this._onClickSet} />
            </div>
            <br></br>
            <div className="beginning-balance__button-wrapper">
              <ReactToPrint
                pageStyle={printTest}
                onBeforeGetContent={() => {
                  return new Promise((resolve) => {
                    setTimeout(() => {
                      this.setState({ isPrint: false }, resolve);
                    }, 500);
                  });
                }}
                onAfterPrint={() => this.setState({ isPrint: true })}
                trigger={() => (
                  // <div className="manage-registration__footer">
                  <Button title="Cetak/Simpan PDF" />
                  // </div>
                )}
                content={() => componentRef.current}
              />
            </div>
          </div>
          {!isTable && (
            <div className="manage-registration" ref={componentRef}>
              <div className="fund-request__content">
                <table className="table">
                  <thead>
                    <tr>
                      {map(
                        ABSEN_REKAP_LIST_TABLE_TOTAL_FIELDS.label,
                        (field, idx) => (
                          <th key={`table_th_${idx}`}>{field}</th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {map(absensiharian, (list, idx) => (
                      <tr key={`budget_row_${idx}`}>
                        <td>{idx + 1}</td>
                        <td>{list.no_va}</td>
                        <td>{list.name}</td>
                        {this.renderHadir(list)}
                        {this.renderSakit(list)}
                        {this.renderIzin(list)}
                        {this.renderAlpa(list)}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}
AbsensiRekap.propTypes = {
  handleGetLevels: PropTypes.func,
  handleGetclassrooms: PropTypes.func,
  history: PropTypes.object.isRequired,
  getStudentOptions: PropTypes.func,
};
AbsensiRekap.defaultProps = {
  handleGetLevels: noop,
  handleGetclassrooms: noop,
  getStudentOptions: noop,
};
